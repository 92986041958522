import * as React from "react"
import styled from "styled-components"
import { FC, useState } from "react"
import Card from "../../../../components/Card"
import ShareableSelectionPageConfig from "../../../orders/ShareableSelectionPageConfig"
import OrderType from "../../../../model/OrderType"
import InviteByEmail from "../../../orders/InviteByEmail"

const Container = styled.div`
  padding: 10px;
`
const StyledCard = styled(Card)`
  padding: 0px;
`

interface Props {
  giftList: OrderType
  updateList: (giftList: OrderType) => void
  fetchOrder: () => void
}

const GiftInvite: FC<Props> = ({ giftList, updateList, fetchOrder }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  return (
    <Container>
      <StyledCard>
        <ShareableSelectionPageConfig order={giftList} setOrder={updateList} />
      </StyledCard>
      <StyledCard>
        <InviteByEmail order={giftList} onComplete={fetchOrder} />
      </StyledCard>
    </Container>
  )
}

export default GiftInvite
